import './ProductsContent.less'

import { RouteComponentProps } from '@reach/router'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import { fetchBrands, fetchGlobalProducts } from '../../../requests'
import { DatacMessage, DatacOption, DatacTitle } from '../../common'
import { ProductActions, ProductsTable } from '../../shared/ProductsTable'
import { AddProduct } from './AddProduct'

const pageSize = 50

export const ProductsContent: React.FC<RouteComponentProps> = () => {
  const intl = useScopedIntl('')
  const intlTable = useScopedIntl('side_by_side.products.table')
  const [brandOptions, setBrandOptions] = useState<DatacOption[]>([])
  const [reloadTable, setReloadTable] = useState(false)

  useEffect(() => {
    updateBrandList()
  }, [])

  const updateBrandList = () => {
    fetchBrands({
      onSuccess: brands => setBrandOptions(brands.map(brand => ({ value: brand, label: brand }))),
      onRequestError: () => DatacMessage.genericError(intl)
    })
  }

  return (
    <div className="products-content">
      <div className="products-content__header">
        <DatacTitle type="h1">{intlTable('title')}</DatacTitle>
        <div className="products-content__header__add-product">
          <AddProduct
            brandOptions={brandOptions}
            setBrandOptions={setBrandOptions}
            onClose={() => setReloadTable(!reloadTable)}
          />
        </div>
      </div>

      <ProductsTable
        pageSize={pageSize}
        fetchProducts={fetchGlobalProducts}
        brandOptions={brandOptions}
        reloadTable={reloadTable}
        showStats
        allowedActions={[ProductActions.Assign]}
      />
    </div>
  )
}

import { RouteComponentProps, Router } from '@reach/router'
import React, { useContext } from 'react'

import { RedirectNoAccessWrapper } from '../RedirectNoAccessWrapper'
import { UserContext } from '../auth'
import { ProductsContent } from './ProductsContent'

export const Products: React.FC<RouteComponentProps> = () => {
  const { user } = useContext(UserContext)
  return (
    <Router>
      <RedirectNoAccessWrapper hasNoAccess={!user.isSideBySideEnabled} path="/">
        <ProductsContent path="/" />
      </RedirectNoAccessWrapper>
    </Router>
  )
}
